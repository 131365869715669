import axios from '@axios';

export default {
  namespaced: true,
  state: {
    walletInfo: {
      availableBalance: "0.00",
      lockedBalance: "0.00",
      totalBalance: "0.00"
    }
  },
  getters: {
    wallet: state => {
        return state.walletInfo
    },
  },
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, data) {
      state.walletInfo.availableBalance = data.availableBalance
      state.walletInfo.lockedBalance = data.lockedBalance
      state.walletInfo.totalBalance = data.totalBalance
    },
  },
  actions: {
    fetchWallet(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/wallet')
          .then(res => {
            ctx.commit('UPDATE_CART_ITEMS_COUNT', res.data.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
