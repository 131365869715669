import Vue from 'vue'
import useJwt from '@/auth/jwt/useJwt'

// axios
import axios from 'axios'
console.log(process.env.VUE_APP_GIFT_API_URL)



const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_GIFT_API_URL,
  timeout: 600000,
})

axiosIns.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('userData')

    // Reset ability

    // Redirect to login page
    this.$router.push({ name: 'auth-login' })
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
